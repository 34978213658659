import { Routes } from '@angular/router';
import { createContextLogger } from '@konnektu/helpers';
import { environment } from '../../environments/environment';

const logger = createContextLogger('useTenantExtensions');

export async function useTenantExtensions(tenantCode: string): Promise<Routes> {
  // TODO: maybe get from assets or from remote?
  const extensions = environment.extensions;
  const extensionNames = (extensions as any)[tenantCode] as
    | string[]
    | undefined;
  if (!extensionNames) {
    return [];
  }

  const extensionsConfig = {} as { [extensionName: string]: any };
  for (const extensionName of extensionNames) {
    const config = await import(`../extensions/${extensionName}/config.json`);
    extensionsConfig[extensionName] = config;
  }
  logger.debug('Building extensions', extensionsConfig);
  const resultRouteConfig: Routes = Object.keys(extensionsConfig).flatMap(
    (extensionName) =>
      extensionsConfig[extensionName].modules
        .filter((m: any) => !m.disabled)
        .map((extensionModule: any) => ({
          path: extensionModule.path,
          loadChildren: () =>
            import(
              `../extensions/${extensionName}/${extensionModule.file}`
            ).then((m) => m[extensionModule.file.split('.')[0] + 'Module']),
          data: {
            preload: extensionModule.preload
          }
        }))
  );

  return resultRouteConfig;
}
