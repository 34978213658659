import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TUI_ALERT_POSITION, TuiRootModule } from '@taiga-ui/core';

@Component({
  selector: 'knk-root',
  standalone: true,
  template: `
    <tui-root>
      <router-outlet></router-outlet>
      <ng-container ngProjectAs="tuiOverContent"> </ng-container>
      <ng-container ngProjectAs="tuiOverDialogs"> </ng-container>
      <ng-container ngProjectAs="tuiOverAlerts"> </ng-container>
      <ng-container ngProjectAs="tuiOverPortals"> </ng-container>
      <ng-container ngProjectAs="tuiOverHints"> </ng-container>
    </tui-root>
  `,
  styles: [
    `
      tui-root {
        height: 100vh;
      }
    `
  ],
  imports: [TuiRootModule, RouterModule],
  providers: [{ provide: TUI_ALERT_POSITION, useValue: '2rem auto' }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {}
