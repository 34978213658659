import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  Routes
} from '@angular/router';
import { createContextLogger } from '@konnektu/helpers';
import {
  MarketingCampaignDetailDto,
  MarketingCampaignProcessListItemDto,
  MarketingCampaignService
} from '@konnektu/marketing-campaigns/data';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { format } from 'date-fns';

const allowedTenants = [
  'khl',
  'unilever',
  'staging',
  'astrazeneca',
  'cdpdemo',
  'akbars',
  'admiral',
  'amur',
  'avangard',
  'barys',
  'cska',
  'dynamoomn',
  'lokomotiv',
  'metallurg',
  'neftekhimik',
  'sibir',
  'severstal',
  'spartak',
  'torpedo',
  'traktor',
  'vityaz',
  'internal'
];

export const campaignTenantGuard: CanActivateFn = (route, state) => {
  const tenantCode = inject(INSTANCE_CODE);
  const router = inject(Router);
  const logger = createContextLogger('campaignTenantGuard');

  logger.debug(`Checking access for tenant: ${tenantCode}`);

  if (allowedTenants.includes(tenantCode)) {
    logger.debug(`Access granted for tenant: ${tenantCode}`);
    return true;
  } else {
    return router.createUrlTree([`/${tenantCode}/dashboard`]);
  }
};
export const MARKETING_CAMPAIGN_ROUTES: Routes = [
  {
    path: 'marketing-campaigns',
    canActivateChild: [campaignTenantGuard],
    providers: [MarketingCampaignService],
    data: {
      breadcrumb: 'marketingCampaign.breadcrumb.sectionBreadcrumb'
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@konnektu/marketing-campaigns/section').then(
            (c) => c.MarketingCampaignSectionComponent
          ),
        data: {
          breadcrumb: null
        }
      },
      {
        path: ':id',
        data: {
          breadcrumb: (data: {
            marketingCampaign: MarketingCampaignDetailDto;
          }) => data.marketingCampaign.name
        },
        resolve: {
          marketingCampaign: (snapshot: ActivatedRouteSnapshot) =>
            inject(MarketingCampaignService).getMarketingCampaign(
              Number(snapshot.paramMap.get('id'))
            )
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: null
            },
            loadComponent: () =>
              import('@konnektu/marketing-campaigns/bpmn-constructor').then(
                (m) => m.CampaignConstructorComponent
              )
          },
          {
            path: 'processes/:id',
            loadComponent: () =>
              import('@konnektu/marketing-campaigns/bpmn-constructor').then(
                (m) => m.ProcessViewerComponent
              ),
            data: {
              breadcrumb: (data: {
                marketingCampaign: MarketingCampaignDetailDto;
                marketingCampaignProcess: MarketingCampaignProcessListItemDto;
              }) =>
                format(
                  new Date(data.marketingCampaignProcess.startedAt),
                  'dd.MM.yyyy HH:mm'
                )
            },
            resolve: {
              marketingCampaignProcess: (snapshot: ActivatedRouteSnapshot) =>
                inject(MarketingCampaignService).getMarketingCampaignProcess(
                  Number(snapshot.paramMap.get('id'))
                )
            }
          }
        ]
      }
    ]
  }
];
