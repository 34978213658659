export const environment = {
  name: 'staging',
  apiUrl: '',
  extensions: {
    lokomotiv: ['sport-simple-filters', 'sport-user', 'sport-order'],
    sibir: ['sport-simple-filters', 'sport-user', 'sport-order'],
    dynamomn: ['sport-simple-filters', 'sport-user', 'sport-order'],
    traktor: ['sport-simple-filters', 'sport-user', 'sport-order'],
    severstal: ['sport-simple-filters', 'sport-user', 'sport-order'],
    adengi: ['demo-user'],
    obidemo: ['demo-user'],
    metallurg: ['sport-simple-filters', 'sport-user', 'sport-order'],
    neftekhimik: ['sport-simple-filters', 'sport-user', 'sport-order'],
    akbars: ['sport-user', 'sport-order'],
    tpsdemo: ['demo-user'],
    barys: ['sport-simple-filters', 'sport-order', 'sport-user'],
    spartak: ['sport-simple-filters', 'sport-user', 'sport-order'],
    cdpdemo: ['demo-user'],
    avangard: ['sport-simple-filters', 'sport-user', 'sport-order'],
    retaildemo: ['demo-user'],
    amur: ['sport-simple-filters', 'sport-user', 'sport-order'],
    vityaz: ['sport-simple-filters', 'sport-user', 'sport-order'],
    rpldemo: ['rpl-user', 'loyalty-program'],
    fsk: ['fsk-integrations'],
    cdppharmademo: [
      'cdppharmademo-client',
      'pharma-questionnaire',
      'cdppharmademo-task'
    ],
    binnopharm: [
      'pharma-client',
      'pharma-questionnaire',
      'pharma-task',
      'ma-communication'
    ],
    admiral: ['sport-simple-filters', 'sport-user', 'sport-order'],
    khl: ['sport-simple-filters', 'sport-user', 'sport-order'],
    cska: ['sport-simple-filters', 'sport-user', 'sport-order'],
    'rpl-demo': ['khl-order', 'khl-user', 'loyalty-program'],
    torpedo: ['sport-simple-filters', 'sport-user', 'sport-order'],
    'fanzilla-demo': ['khl-order', 'khl-user', 'loyalty-program'],
    uzcdp: ['demo-user']
  }
};
