import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButtonModule } from '@taiga-ui/core';

@Component({
  selector: 'knk-not-found-page',
  styleUrls: ['not-found-page.component.scss'],
  templateUrl: './not-found-page.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, TuiButtonModule]
})
export class NotFoundPageComponent {
  private readonly router = inject(Router);

  private readonly location = inject(Location);

  protected readonly defaultNotFoundText = 'notFoundPage.defaultText';

  protected readonly providedNotFoundText?: string =
    this.router.getCurrentNavigation()?.extras.state?.['notFoundText'];

  protected goBack() {
    this.location.back();
  }
}
