import { HttpClient, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { bootstrapTenantApplication } from '@konnektu/multitenant';
import { NotFoundPageComponent } from '@konnektu/status-page';
import { provideKonnektuTaiga } from '@konnektu/taiga';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'cronstrue';
import 'cronstrue/locales/en';
import 'cronstrue/locales/ru';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler
} from 'ngx-translate-messageformat-compiler';
import 'reflect-metadata';
import { AppComponent } from './app/app.component';
import {
  buildTenantConfig,
  initStaticTranslations
} from './app/application-config';

export function provideStaticTranslations() {
  return [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
          deps: [HttpClient]
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler
        },
        defaultLanguage: 'ru'
      })
    ),
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: ['en', 'ru'] }
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initStaticTranslations,
      deps: [TranslateService]
    }
  ];
}

export async function bootstrapTenant(code: string) {
  await bootstrapApplication(AppComponent, await buildTenantConfig(code)).catch(
    (err) => {
      // eslint-disable-next-line no-console
      console.error('Error bootstrapping AppModule', err);
    }
  );
}

export async function bootstrapNotFound() {
  await bootstrapApplication(AppComponent, {
    providers: [
      provideAnimationsAsync(),
      provideRouter([
        {
          path: '**',
          component: NotFoundPageComponent
        }
      ]),
      provideHttpClient(),
      provideStaticTranslations(),
      provideKonnektuTaiga()
    ]
  });
}

function checkInstance(tenantCode: string) {
  return fetch(`${tenantCode}/ui-api/auth/checkinstance`)
    .then((resp) => resp.json())
    .then((data) => data.data);
}

export const firstUrlSegmentTenantCodeProvider = () =>
  window.location.pathname.split('/')[1];

bootstrapTenantApplication({
  tenantCodeProvider: firstUrlSegmentTenantCodeProvider,
  checkTenant: checkInstance,
  tenantFound: bootstrapTenant,
  tenantNotFound: bootstrapNotFound
});
