import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  Injectable,
  importProvidersFrom,
  inject
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadingStrategy,
  Route,
  provideRouter,
  withPreloading
} from '@angular/router';
import { AnalyticsService } from '@konnektu/analytics-data';
import { AuthService, provideLegacyAuth } from '@konnektu/auth';
import { GigachatService } from '@konnektu/gigachat/data';
import {
  TRANSLATOR,
  Translator,
  createContextLogger,
  isDefined
} from '@konnektu/helpers';
import {
  iconChevronLeft,
  iconChevronRight,
  provideIcons,
  provideIconsConfig
} from '@konnektu/icons';
import { METASTORE_API_URL, MetastoreModule } from '@konnektu/metastore';
import { MENU_ITEMS, MENU_MAIN_PATH } from '@konnektu/sidebar-layout';
import { provideKonnektuTaiga } from '@konnektu/taiga';
import { API_URL, INSTANCE_CODE } from '@konnektu/tokens';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { merge } from 'lodash-es';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler
} from 'ngx-translate-messageformat-compiler';
import { Observable, concatMap, filter, map, of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import { createTenantRouting } from './application-routes';
import { createMenuItems } from './menu-items';

export function initStaticTranslations(translateService: TranslateService) {
  return () =>
    new Promise<void>((resolve) => {
      const logger = createContextLogger('StaticTranslationInitializer');
      translateService.langs = ['ru', 'en'];
      translateService.onLangChange.subscribe((event) => {
        localStorage.setItem('lang', event.lang);
      });
      const langToSet = localStorage.getItem('lang') ?? 'ru';
      if (langToSet !== translateService.currentLang) {
        translateService.use(langToSet);
      }
      logger.debug('Initialized static translations');
      resolve();
    });
}

@Injectable({ providedIn: 'root' })
export class ModuleLoadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      return load();
    }
    return of(null);
  }
}

class ApiTranslateLoader implements TranslateLoader {
  private readonly defaultLoader: TranslateHttpLoader;

  constructor(
    private readonly http: HttpClient,
    private readonly instanceCode: string
  ) {
    this.defaultLoader = new TranslateHttpLoader(
      http,
      './assets/i18n/',
      '.json'
    );
  }

  getTranslation(lang: string) {
    return this.http
      .get<{
        overrides: object;
        translations: object;
      }>(
        `${this.instanceCode}/api/uzilla/v1/translations?key=main&lang=${lang}`
      )
      .pipe(
        concatMap((resp) =>
          this.defaultLoader
            .getTranslation(lang)
            .pipe(
              map((staticTranslations) =>
                merge(staticTranslations, resp.translations, resp.overrides)
              )
            )
        )
      );
  }
}

export function provideTranslations() {
  return [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient, code: string) =>
            new ApiTranslateLoader(http, code),
          deps: [HttpClient, INSTANCE_CODE]
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler
        },
        defaultLanguage: 'ru'
      })
    ),
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: ['en', 'ru'] }
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initStaticTranslations,
      deps: [TranslateService]
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd.MM.yyyy HH:mm' }
    },
    {
      provide: TRANSLATOR,
      useFactory: (translate: TranslateService) =>
        ({
          lang$: translate.onLangChange,
          translate(key, params) {
            return translate.get(key, params);
          },
          translateSync(key, params) {
            return translate.instant(key, params);
          },
          use(lang) {
            return translate.use(lang);
          },
          get lang() {
            return translate.currentLang;
          },
          get langs() {
            return translate.langs;
          },
          set langs(langs: string[]) {
            translate.langs = langs;
          },
          defaultLang$: translate.onDefaultLangChange,
          translations$: translate.onTranslationChange,
          getParsedResult(
            tranlations: any,
            key: string,
            interpolateParams?: object
          ) {
            return translate.getParsedResult(
              tranlations,
              key,
              interpolateParams
            );
          }
        }) as Translator,
      deps: [TranslateService]
    }
  ];
}

export function provideNgrx() {
  return importProvidersFrom(
    StoreModule.forRoot(
      {
        router: routerReducer
      },
      {
        runtimeChecks: {
          strictActionImmutability: false,
          strictActionSerializability: false,
          strictActionTypeUniqueness: environment.name !== 'production',
          strictActionWithinNgZone: environment.name !== 'production',
          strictStateImmutability: environment.name !== 'production',
          strictStateSerializability: false
        }
      }
    ),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot()
  );
}

export function provideMultitenancy(tenantCode: string) {
  return [
    importProvidersFrom(MetastoreModule.forRoot()),
    {
      provide: INSTANCE_CODE,
      useValue: tenantCode
    },
    {
      provide: API_URL,
      useFactory: (instanceCode: string) =>
        environment.apiUrl
          ? `${environment.apiUrl}/${instanceCode}/ui-api`
          : `${instanceCode}/ui-api`,
      deps: [INSTANCE_CODE]
    },
    {
      provide: METASTORE_API_URL,
      useFactory: (apiUrl: string) => `${apiUrl}/metastore`,
      deps: [API_URL]
    }
  ];
}

export function provideComponents() {
  return [
    provideIconsConfig({
      defaultSize: 'lg'
    }),
    provideIcons([iconChevronLeft, iconChevronRight])
  ];
}

export function provideMaterial() {
  return importProvidersFrom(
    // TODO: hack to fix DI in schema create and copy dialog. fix this after schema page will be merged!!!
    MatTooltipModule,
    MatMenuModule
  );
}

export function provideMonacoEditor() {
  return importProvidersFrom(MonacoEditorModule.forRoot());
}

export function provideUzillaMenuItems(tenantCode: string) {
  return {
    provide: MENU_ITEMS,
    useFactory: () => {
      const authService = inject(AuthService);
      const analyticsService = inject(AnalyticsService);
      const gigachatService = inject(GigachatService);

      return authService.isLoggedIn$.pipe(
        filter(isDefined),
        concatMap(() => gigachatService.isGigachatEnabled),
        switchMap((isGigachatEnabled) =>
          analyticsService
            .queryAllDashboards()
            .pipe(
              map((dashboards) =>
                createMenuItems(tenantCode, dashboards, isGigachatEnabled)
              )
            )
        )
      );
    }
  };
}

function provideMenuMainPath(tenantCode: string) {
  return {
    provide: MENU_MAIN_PATH,
    useValue: `${tenantCode}/dashboard`
  };
}

export async function buildTenantConfig(
  tenantCode: string
): Promise<ApplicationConfig> {
  return {
    providers: [
      provideLegacyAuth(),
      provideAnimations(),
      provideKonnektuTaiga(),
      provideRouter(
        await createTenantRouting(tenantCode),
        withPreloading(ModuleLoadingStrategy)
      ),
      provideTranslations(),
      provideMultitenancy(tenantCode),
      provideNgrx(),
      provideComponents(),
      provideMaterial(),
      provideMonacoEditor(),
      provideUzillaMenuItems(tenantCode),
      provideMenuMainPath(tenantCode)
    ]
  };
}
