import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

@Component({
  selector: 'knk-error-page',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  private readonly route = inject(ActivatedRoute);

  private readonly router = inject(Router);

  protected readonly defaultErrorText = 'errorPage.defaultText';

  protected readonly errorText = toSignal(
    this.route.data.pipe(
      map(
        (data) =>
          data['errorText'] ||
          this.router.getCurrentNavigation()?.extras.state?.['errorText'] ||
          this.defaultErrorText
      )
    ),
    { initialValue: this.defaultErrorText }
  );
}
